.bodyColor {
  /* background-color: rgb(223, 22, 22) !important; */
}
.loginformnew {
  background-color: #ffffff;
  height: 100vh;
}

.loginform_containernew {
  width: 1017px;
  margin: auto;
  /* max-height: calc(100vh - 4vw);
  overflow-y: auto; */
  position: relative;
}

.p-viewer {
  z-index: 9999;
  position: absolute;
  top: 27%;
  right: 24%;
}
.p-viewer-mob {
  z-index: 1;
  position: absolute;
  top: 22px;
  right: 17px;
}

.fa-eye {
  color: #000;
}

.login_container {
}
.login_image_container {
  position: relative;
}
.login_uhs_logo {
  position: absolute;
  left:64px;
  /* top: 52px; */
  height: 78px;
}
.login_main_image {
  width: 100%;
  margin-top: 14px;
}
.login_input_container {
  margin-top: 44px;
  position: relative;
}
.login_input_container_reset_div {
  margin-top: 44px;
  position: relative;
  /* border:1px solid red; */
  display: flex;
}

.login_input_username {
  outline-width: 0px;
  width: 376px;
  padding: 14px 30px;
  border-radius: 100px;
  border: solid 1px #697a86;
  font-family: 'Oswald', sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 1;
  font-stretch: normal;
  font-style: normal;
  color: #697a86;
  background-color: #fff;
  margin-right: 40px;
}
.login_input_username:focus,
.login_input_username:active {
  border: solid 1px #89969f;
  color: #89969f;
  background-color: #fff;
}
.login_button {
  width: 180px;
  padding: 14px 30px;
  border-radius: 100px;
  background-color: #e9716f;
  outline-width: 0px;
  border: none;
  color: #ffffff;
  font-family: 'Oswald', sans-serif;
  font-size: 24px;
}
.login_error {
  font-size: 14px;
  color: red;
  margin-left: 20px;
  margin-top: 5px;
}

.login_button:hover {
  border: none;
  outline: none;
}
.login_button:focus {
  border: none;
  outline: none;
}
.login_bottom_text {
  text-align: center;
  padding-top: 44px;
  /* padding-bottom: 120px; */
  padding-bottom: 30px;
}

.login_bottom_subtext {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 30px;
  letter-spacing: normal;
  color: #000000;
}
.login_plese_text {
  font-weight: 500;
  color: #e9716f;
}

.login_new_error_text {
  color: red;
  border: 1px solid #e9716f;
  margin-top: 3vw;
  font-family: 'Oswald', sans-serif;
  font-size: 1.6vw;
  font-weight: 400;
  padding: 1.2vw 4vw;
}
.forgot-input-con {
  margin-left: 300px;
}

/* =========================================mobile login screen=============== */
.mobile_login_new_error_text {
  color: red;
  border: 1px solid #e9716f;
  margin-top: 3vw;
  font-family: 'Oswald', sans-serif;
  font-size: 3.4vw;
  font-weight: 400;
  padding: 2.2vw 6vw;
}
.loginmobile {
  background-color: #ffffff;
}
.loginmobile_header {
  height: 56px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
  background-color: #543379;
  padding: 0 28vw;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 999;
  margin-bottom: 26px;
}

.loginmobile_header_text {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.15px;
  color: #ffffff;
}
.loginmobile_container {
  padding: 0px 16px;
}

.loginmobile_image_container {
  text-align: center;
  margin-bottom: 30px;
}

.loginmobile_logo {
  width: 60%;
}
.loginmobile_banner {
  width: 100%;
  margin-top: 26px;
  margin-bottom: 26px;
}

.loginmobile_input_container {
}

.loginmobile_input_username {
  outline-width: 0px;
  padding: 16px 30px;
  padding-right: 43px;
  width: 100%;
  border-radius: 100px;
  border: solid 1px #89969f;
  font-family: 'Oswald', sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  /* position: ƒ√; */
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #89969f;
}
.mobile-linput {
  margin-bottom: 15px;
}
.mobile-linput > input:first-child {
  margin-bottom: 30px;
}
.forgot_text-margin {
  margin-left: 448px;
}
.loginmobile_forgot_text {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.96;
  letter-spacing: normal;
  color: #e9716f;
  cursor: pointer;
}

.mobile_forgot_text {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.96;
  letter-spacing: normal;
  color: #e9716f;
  cursor: pointer;
  padding-left: 30px;
}

.loginmobile_button {
  padding: 16px 30px;
  width: 100%;
  border-radius: 100px;
  background-color: #e9716f;
  border: none;
  margin-top: 30px;
  font-family: 'Oswald', sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #ffffff;
}
.loginmobile_error {
  font-size: 14px;
  color: red;
  margin-left: 20px;
  margin-top: 5px;
}

.loginmobile_button:hover {
  border: none;
  outline: none;
}
.loginmobile_button:focus {
  border: none;
  outline: none;
}
.loginmobile_bottom_text {
  padding: 30px 0px 60px 0px;
  text-align: center;
}

.loginmobile_bottom_subtext {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: normal;
  color: #000000;
}

.loginmobile_bottom_subtext3 {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: normal;
  color: #000000;

  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid red;
}

.loginmobile_bottom_subtext2 {
  margin-top: 10px;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: normal;
  color: #000000;
}

.loginmobile_please_check {
  font-weight: 500;
  color: #e9716f;
}

.rege {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  background-color: #ffffff;
}

.rege_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rege_button {
  /* width: 176px; */
  padding: 0px 30px;
  height: 65px;
  border-radius: 50px;
  background-color: #e9716f;
  outline-width: 0px;
  border: none;
  color: #ffffff;
  margin-bottom: 20px;
}

.rege_button:hover {
  border: none;
  outline: none;
}
.rege_button:focus {
  border: none;
  outline: none;
}

.rege_left_container {
  /* border:1px solid red; */
  width: 243px;
  margin-right: 30px;
}
.rege_touble_text {
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5f2161;
}
.rege_subtext {
  margin-top: 10px;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000000;
}
.rege_bottom_text {
  margin: 30px 0px;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.96;
  letter-spacing: normal;
  color: #000000;
}
.rege_goto {
  font-weight: 500;
  color: #e9716f;
  cursor: pointer;
}

.forgotmobile_text_details {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000000;
}

.loginmobile_text_blue {
  /* width: 243px; */
  /* height: 23px; */
  /* font-family: Roboto; */
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5f2161;
  margin-bottom: 10px;
}

/* ==================================Reset container========================== */

.reset_login_uhs_logo {
  margin-top: 2vw;
  margin-left: -10vw;
  height: 4.5vw;
  object-fit: contain;
  /* width: 252px; */
}
.reset_login_main_image {
  height: 40vw;
  object-fit: contain;
  /* border: 1px solid red; */
}
.reset_container {
  /* border:1px solid red; */
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  margin-top: 2vw;
}

.reset_input_username {
  outline-width: 0px;
  border: none;
  padding: 2vw 2vw;
  /* border:1px solid #89969f; */
  width: 22vw;
  height: 4vw;
  border-radius: 10vw;
  border: solid 1px #89969f;
  background-color: rgba(65, 181, 194, 0);

  /* font-family: 'Tungsten'; */
  font-family: 'Oswald', sans-serif;
  font-size: 1.4vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.2;
  letter-spacing: 1.2;
  color: #89969f;

  margin: 1vw 1vw;
}

.reset_button {
  /* width: 176px; */
  padding: 0vw 2vw;
  font-family: 'Oswald', sans-serif;
  font-size: 1.4vw;
  height: 4vw;
  border-radius: 50px;
  background-color: #e9716f;
  letter-spacing: 1.2;
  outline-width: 0px;
  border: none;
  color: #ffffff;
  margin-bottom: 2vw;
  margin-top: 2vw;
}

.reset_bottom_text {
  margin: 1vw 0px;
  font-size: 1.2vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.96;
  letter-spacing: normal;
  color: #000000;
}

.reset_goto {
  font-weight: 500;
  color: #e9716f;
}
.reset_note {
  margin-top: 2vw;
  /* width: 303px; */
  /* height: 37px; */
  /* font-family: Roboto; */
  font-size: 1.4vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000000;
}

.reset_errormsg {
  margin-top: 2vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4vw;
  font-weight: 500;
  color: red;
  padding: 1vw 3vw;
  border: 1px solid red;
}
/* ===============================mobile reset====================== */

.mob_reset_loginmobile_logo {
  /* border: 1px solid red; */
  object-fit: contain;
  width: 35vw;
  /* height:40vw; */
}
.mob_reset_loginmobile_banner {
  object-fit: contain;
  width: 90vw;
  /* height: 331px; */
}
.mob_reset_container {
  margin: 4vw 0vw;
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mob_reset_input_username {
  outline-width: 0px;
  border: none;
  padding: 4vw 4vw;
  /* border:1px solid #89969f; */
  width: 48vw;
  height: 8vw;
  border-radius: 10vw;
  border: solid 1px #89969f;
  background-color: rgba(65, 181, 194, 0);

  /* font-family: 'Tungsten'; */
  font-family: 'Oswald', sans-serif;
  font-size: 3.4vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.2;
  letter-spacing: 1.2;
  color: #89969f;

  margin: 2vw 1vw;
}

.mob_reset_button {
  /* width: 176px; */
  width: 48vw;
  padding: 2vw 0vw;
  font-family: 'Oswald', sans-serif;
  font-size: 3.4vw;
  /* height: 8vw; */
  border-radius: 50px;
  background-color: #e9716f;
  letter-spacing: 1.2;
  outline-width: 0px;
  border: none;
  color: #ffffff;
  margin-bottom: 2vw;
  margin-top: 2vw;
}
.mob_reset_bottom_text {
  margin: 2vw 0px;
  font-size: 2.8vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.96;
  letter-spacing: normal;
  color: #000000;
}

.mob_reset_goto {
  font-weight: 500;
  color: #e9716f;
}

.mob_reset_note {
  padding: 0vw 5vw;
  margin-top: 4vw;
  /* width: 303px; */
  /* height: 37px; */
  /* font-family: Roboto; */
  font-size: 3.4vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000000;
}

.mob_reset_errormsg {
  margin: 2vw 0vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3.4vw;
  font-weight: 500;
  color: red;
  padding: 1vw 3vw;
  border: 1px solid red;
}

/* =====================FirstTimepasswordchange.js============= */
.firsttime_changepassword_text {
  margin-top: 2vw;
  font-family: 'Roboto', sans-serif;
  font-size: 1.9vw;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: #000000;
}

.firsttime_changepassword_subtext {
  font-family: 'Roboto', sans-serif;
  font-size: 1.3vw;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: #000000;
}

.inputpasswordbox_input_username {
  width: 18vw;
  height: 4vw;
  outline-width: 0px;
  border: none;

  font-family: 'Oswald', sans-serif;
  font-size: 1.4vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.2;
  letter-spacing: 1.2;
  color: #89969f;
  /* outline-width: 0px;
  border: none;
  padding: 2vw 2vw;
  width: 22vw;
  height: 4vw;
  border-radius: 10vw;
  border: solid 1px #89969f;
  background-color: rgba(65, 181, 194, 0);
  font-family: 'Oswald', sans-serif;
  font-size: 1.4vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.2;
  letter-spacing: 1.2;
  color: #89969f;

  margin: 1vw 1vw; */
}

.firsttime_input_box_container {
  border: solid 1px #89969f;
  padding: 0vw 2vw;
  /* width: 22vw; */
  /* height: 4vw; */
  border-radius: 10vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin: 1vw 1vw; */
}
.firsttime_margin_class {
  margin: 1vw 1vw;
}

.firsettime_send_button {
  /* width: 200px; */
  padding: 0vw 6vw;
  font-family: 'Oswald', sans-serif;
  font-size: 1.4vw;
  height: 4vw;
  border-radius: 50px;
  background-color: #e9716f;
  letter-spacing: 1.2;
  outline-width: 0px;
  border: none;
  color: #ffffff;
  margin-bottom: 2vw;
  margin-top: 2vw;
  cursor: pointer;
}

.firsttimemobile_bottom_text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4vw;
  margin-bottom: 4vw;
}
.firsttimemobile_bottom_subtext {
  font-family: 'Roboto', sans-serif;
  font-size: 4.4vw;
  font-weight: 300;
  /* font-weight: normal; */
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: normal;
  color: #000000;
  /* padding:0px 20px; */
}

.firsttimemobile_bottom_subtext2 {
  margin-top: 2vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-family: 'Roboto', sans-serif;
  font-size: 3.4vw;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: normal;
  color: #000000;
}

.a-form-ctrl_web {
  /* position: relative;
 margin-bottom: 5px; */
  /* width:200px; */
}

.a-form-ctrl_mobile {
  /* position: relative;
margin-bottom: 5px; */
  /* width:200px; */
  width: 80%;
}

.mobile_firsttime_input_box_container {
  border: solid 1px #89969f;
  padding: 0vw 2vw;
  /* width: 22vw; */
  /* height: 4vw; */
  border-radius: 10vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin: 1vw 1vw; */
}

.mobile_inputpasswordbox_input_username {
  border: 1px solid red;
  width: 18vw;
  height: 8vw;
  outline-width: 0px;
  border: none;

  font-family: 'Oswald', sans-serif;
  font-size: 3.4vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.2;
  letter-spacing: 1.2;
  color: #89969f;
  /* outline-width: 0px;
  border: none;
  padding: 2vw 2vw;
  width: 22vw;
  height: 4vw;
  border-radius: 10vw;
  border: solid 1px #89969f;
  background-color: rgba(65, 181, 194, 0);
  font-family: 'Oswald', sans-serif;
  font-size: 1.4vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.2;
  letter-spacing: 1.2;
  color: #89969f;

  margin: 1vw 1vw; */
}

.checkregister_blue_text_container {
  position: absolute;
  top: 0px;
  left: 0px;
}
.checkregister_having_text {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: #5f2161;
  margin-bottom: 4px;
}
.checkregister_having_subtext {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 18px;
  letter-spacing: normal;
  color: #000000;
}
.checkregister_button {
  width: 254px;
  padding: 14px 30px;
  border-radius: 100px;
  background-color: #e9716f;
  border: none;
  color: #ffffff;
  font-family: 'Oswald', sans-serif;
  font-size: 24px;
  text-align: center;
}
.checkregister_goto_subtext {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: #000000;
  padding-top: 44px;
  /* padding-bottom: 120px; */
  padding-bottom: 30px;
  text-align: center;
}

.xxxxxreset_input_username {
  outline-width: 0px;
  border: none;
  padding: 2vw 2vw;
  /* border:1px solid #89969f; */
  width: 24vw;
  height: 4.5vw;
  border-radius: 10vw;
  border: solid 1px #89969f;
  background-color: rgba(65, 181, 194, 0);
  /* font-family: 'Tungsten'; */
  font-family: 'Oswald', sans-serif;
  font-size: 1.6vw;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.2;
  letter-spacing: 1.2;
  color: #89969f;
  margin-right: 2vw;
  /* margin: 1vw 1vw; */
}
.xxxxlogin_input_username {
  outline-width: 0px;
  border: none;
  /* padding: 20px 20px; */
  /* border:1px solid #89969f; */
  width: 24vw;
  height: 4.5vw;
  border-radius: 100px;
  border: solid 1px #89969f;
  background-color: rgba(65, 181, 194, 0);

  /* font-family: 'Tungsten'; */
  font-family: 'Oswald', sans-serif;
  font-size: 1.6vw;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  /* line-height: 0.2; */
  /* letter-spacing: 0.2; */
  color: #89969f;

  margin-right: 2vw;
}
.inputpasswordbox_input_div {
  width: 20vw;
  outline-width: 0px;
  border: none;
  padding: 1vw 1vw;
  /* padding: 20px 20px; */
  /* border:1px solid #89969f; */
  /* width: 18vw; */
  /* height: 4.5vw; */
  /* border: solid 1px #89969f; */
  background-color: rgba(65, 181, 194, 0);

  font-family: 'Oswald', sans-serif;
  font-size: 1.6vw;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.2;
  letter-spacing: 1.2;
  color: #89969f;
  /* margin-right: 2vw; */
}

.demoinputpasswordbox_input_username {
  width: 18vw;
  height: 4vw;
  outline-width: 0px;
  border: none;

  font-family: 'Oswald', sans-serif;
  font-size: 1.4vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.2;
  letter-spacing: 1.2;
  color: #89969f;
}

.inputpassword_box_container {
  /* border:1px solid red; */
  margin-right: 2vw;
  width: 25vw;
}
.margin_top {
  margin-top: 2vw;
}
@media (max-width: 1024px) and (min-width: 1000px) {
  .loginform_containernew {
    width: 840px;
  }
  .login_uhs_logo {
    height: 58px;
  }
  .login_input_username {
    width: 302px;
    font-size: 20px;
  }
  .login_button {
    width: 150px;
    font-size: 20px;
  }
  .forgot_text-margin {
    margin-left: 375px;
  }
  .checkregister_button {
    width: 222px;
    font-size: 20px;
    padding: 14px 30px;
  }
  .forgot-input-con > .login_input_username {
    width: 276px !important;
  }
}

.loginmobile_input_username_password_box {
  outline-width: 0px;
  padding: 16px 30px;
  padding-right: 43px;
  width: 100%;
  border-radius: 100px;
  /* border: solid 1px #89969f; */
  border: solid 1px white;
  font-family: 'Oswald', sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  /* position: ƒ√; */
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #89969f;
}

.reset_password_mail_text_msg {
  padding: 10px 0px 20px 0px;
  text-align: center;
}

.passowrd_validation_center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.mfa_resend_code {
  color: blue;
  cursor: pointer;
  margin-bottom: 20px;
}
