.Change-Payment-Method {
  /* width: 207px; */
  height: 22px;
  /* margin: 9px 70px 23px 25px; */
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.15px;
  text-align: left;
  color: #5f2161;
}
.The-fellowship-incurs-additional-fees-related-to-p {
  /* width: 785px;
    height: 48px; */
  /* margin: 14px 28px 17px 25px; */
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
  color: rgba(0, 0, 0, 0.87);
}
.ButtonBG {
  width: 150px;
  height: 40px;
  margin: 0 20px 0 0;
  padding: 0 0.2px 0 0;
  border-radius: 30px;
  border: solid 2px #41b5c2;
  background-color: #41b5c2;
  color: #ffffff;

  font-family: Tungsten;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 1.44px;
  text-align: center;
  color: #ffffff;
}
